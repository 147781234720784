import { PUBLIC_HANA_BACKEND_BASE_URL } from '$env/static/public';
import type { HealthData } from '$libs/market';
import type { AaveV3FormattedMarketReserves, AaveV3FormattedUserReserves } from './types';

enum HanaServiceRoutes {
  MARKET = '/api/v2/market',
  USER = '/api/v2/user',
  DISCLAIMER = '/api/v1/disclaimer',
  HEALTH = '/api/v2/health',
}

export const getHealth = async (): Promise<HealthData[]> => {
  try {
    const response = await fetch(`${PUBLIC_HANA_BACKEND_BASE_URL}${HanaServiceRoutes.HEALTH}`);
    const data = await response.json();
    return data;
  } catch (error) {
    return [];
  }
};

export const getMarketReserves = async (): Promise<AaveV3FormattedMarketReserves[]> => {
  try {
    const response = await fetch(`${PUBLIC_HANA_BACKEND_BASE_URL}${HanaServiceRoutes.MARKET}`);
    const data = await response.json();
    return data;
  } catch (error) {
    return [];
  }
};

export const getUserReserves = async (account: `0x${string}`): Promise<AaveV3FormattedUserReserves> => {
  try {
    const response = await fetch(`${PUBLIC_HANA_BACKEND_BASE_URL}${HanaServiceRoutes.USER}/${account}`);
    const data = await response.json();
    return data;
  } catch (error) {
    return {
      userReservesData: [],
      totalLiquidityMarketReferenceCurrency: '',
      totalLiquidityUSD: '',
      totalCollateralMarketReferenceCurrency: '',
      totalCollateralUSD: '',
      totalBorrowsMarketReferenceCurrency: '',
      totalBorrowsUSD: '',
      netWorthUSD: '',
      availableBorrowsMarketReferenceCurrency: '',
      availableBorrowsUSD: '',
      currentLoanToValue: '',
      currentLiquidationThreshold: '',
      healthFactor: '',
      isInIsolationMode: false,
      calculatedUserIncentives: {},
    };
  }
};

export const getDisclaimer = async (account: `0x${string}`) => {
  try {
    const response = await fetch(`${PUBLIC_HANA_BACKEND_BASE_URL}${HanaServiceRoutes.DISCLAIMER}/${account}`);
    const data = await response.json();
    return data.expiration;
  } catch (error) {
    return 0;
  }
};

export const createDisclaimer = async (
  account: `0x${string}`,
  signature: string,
  message: string,
  expiration: string,
) => {
  try {
    const response = await fetch(`${PUBLIC_HANA_BACKEND_BASE_URL}${HanaServiceRoutes.DISCLAIMER}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        address: account,
        signature,
        message,
        expiration,
      }),
    });
    const data = await response.json();
    return {
      data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};
