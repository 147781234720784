import { markets } from '$configs/market';
import { PUBLIC_ENVIRONMENT_STAGE } from '$env/static/public';
import type { HanaHealthData, HanaUserReserves, HealthData, MarketData } from '$libs/market';
import type { AaveV3FormattedMarketReservesV2, AaveV3FormattedUserReserves, AaveV3IUserReserve, AaveV3Incentive } from '$libs/service/types';
import { IEnvironment } from '../../types';
import type { IAsset, IRewards, UserData } from './types';

export const convertAaveUserDataToHanaData = (
  aaveUserData: AaveV3FormattedUserReserves,
  market: MarketData[],
): UserData => {
  const filteredBorrowedAssets = aaveUserData.userReservesData.filter((_reserve) => +_reserve.scaledVariableDebt !== 0);
  const filteredSuppliedAssets = aaveUserData.userReservesData.filter((_reserve) => +_reserve.underlyingBalance !== 0); // TODO - 0xBotan - Might be wrong

  const _temptotalBorrowLimitUSD = +aaveUserData.totalCollateralUSD * +aaveUserData.currentLoanToValue;
  let _tempCurrentBorrowLimitUSD =
    aaveUserData.availableBorrowsUSD === 'NaN'
      ? +_temptotalBorrowLimitUSD - +aaveUserData.totalBorrowsUSD
      : +aaveUserData.availableBorrowsUSD;
  _tempCurrentBorrowLimitUSD = _tempCurrentBorrowLimitUSD < 0.1 ? 0 : _tempCurrentBorrowLimitUSD;
  const borrowLimitLeeway = Math.min(5, _tempCurrentBorrowLimitUSD * 0.02);
  const _totalBorrowLimitUSD = String(
    +aaveUserData.totalCollateralUSD * +aaveUserData.currentLoanToValue - borrowLimitLeeway,
  );
  const _currentBorrowLimitUSD = String(_tempCurrentBorrowLimitUSD - borrowLimitLeeway);

  return {
    address: '',
    totalSuppliedUSD: aaveUserData.totalLiquidityUSD,
    totalCollateralUSD: aaveUserData.totalCollateralUSD,
    totalBorrowLimitUSD: _totalBorrowLimitUSD,
    currentBorrowLimitUSD: _currentBorrowLimitUSD,
    totalBorrowedUSD: aaveUserData.totalBorrowsUSD,
    netSupplyApy:
      +aaveUserData.totalLiquidityUSD > 0
        ? calculateWeightedAverageSupplyAPY(aaveUserData.totalLiquidityUSD, aaveUserData.userReservesData, market)
        : 0,
    netBorrowApy:
      +aaveUserData.totalBorrowsUSD > 0
        ? calculateWeightedAverageBorrowAPY(aaveUserData.totalBorrowsUSD, aaveUserData.userReservesData, market)
        : 0,
    netApy:
      +aaveUserData.totalLiquidityUSD > 0
        ? calculateWeightedAverageNetAPY(aaveUserData.totalLiquidityUSD, aaveUserData.userReservesData, market)
        : 0,
    healthFactor: aaveUserData.healthFactor,
    suppliedAssets: convertAaveV3ReserveToHanaAsset(filteredSuppliedAssets),
    borrowedAssets: convertAaveV3ReserveToHanaAsset(filteredBorrowedAssets, false),
    loanHealth:
      +aaveUserData.totalCollateralUSD > 0
        ? (+aaveUserData.totalBorrowsUSD * 100) /
          (+aaveUserData.totalCollateralUSD * +aaveUserData.currentLiquidationThreshold)
        : 0,
    ltv: aaveUserData.currentLoanToValue,
    rewards: convertAaveV3RewardsToHanaRewards(aaveUserData.calculatedUserIncentives),
  };
};

const calculateWeightedAverageSupplyAPY = (
  totalSuppliedInUSD: string,
  reserves: AaveV3IUserReserve[],
  market: MarketData[],
): number => {
  let sumOfWeightedApy = 0;
  reserves.forEach((_reserve) => {
    const _market = market?.find((m) => m.address.toLowerCase() === _reserve.reserve.underlyingAsset.toLowerCase());
    let sumOfIncentiveAPY = 0;
    if (_market) {
      if (_market.supplyIncentive && _market.supplyIncentive.length > 0) {
        sumOfIncentiveAPY = _market.supplyIncentive.reduce(
          (accumulator, currentIncentive) =>
            // accumulator + +currentIncentive.incentiveAPR === Infinity ? 0 : +currentIncentive.incentiveAPR,
            accumulator + 0,
          0,
        );
      }

      sumOfWeightedApy += +_reserve.underlyingBalanceUSD * (+_market.supplyApy + sumOfIncentiveAPY);
    }
  });

  return sumOfWeightedApy / +totalSuppliedInUSD;
};

const calculateWeightedAverageBorrowAPY = (
  totalBorrowInUSD: string,
  reserves: AaveV3IUserReserve[],
  market: MarketData[],
): number => {
  let sumOfWeightedApy = 0;
  reserves.forEach((_reserve) => {
    const _market = market?.find((m) => m.address.toLowerCase() === _reserve.reserve.underlyingAsset.toLowerCase());
    let sumOfIncentiveAPY = 0;
    if (_market) {
      if (_market.borrowIncentive && _market.borrowIncentive.length > 0) {
        sumOfIncentiveAPY = _market.borrowIncentive.reduce(
          (accumulator, currentIncentive) =>
            // accumulator + +currentIncentive.incentiveAPR === Infinity ? 0 : +currentIncentive.incentiveAPR,
            accumulator + 0,
          0,
        );
      }
      sumOfWeightedApy += +_reserve.totalBorrowsUSD * (-+_market.borrowApy + sumOfIncentiveAPY);
    }
  });

  return sumOfWeightedApy / +totalBorrowInUSD;
};

const calculateWeightedAverageNetAPY = (
  totalSuppliedInUSD: string,
  reserves: AaveV3IUserReserve[],
  market: MarketData[],
) => {
  let sumOfWeightedSupplyApy = 0;
  let sumOfWeightedBorrowApy = 0;

  reserves.forEach((_reserve) => {
    const _market = market?.find((m) => m.address.toLowerCase() === _reserve.reserve.underlyingAsset.toLowerCase());
    let sumOfIncentiveAPY = 0;
    if (_market) {
      if (_market.supplyIncentive && _market.supplyIncentive.length > 0) {
        sumOfIncentiveAPY = _market.supplyIncentive.reduce(
          (accumulator, currentIncentive) =>
            // accumulator + +currentIncentive.incentiveAPR === Infinity ? 0 : +currentIncentive.incentiveAPR,
            accumulator + 0,
          0,
        );
      }

      sumOfWeightedSupplyApy += +_reserve.underlyingBalanceUSD * (+_market.supplyApy + sumOfIncentiveAPY);
    }
  });

  reserves.forEach((_reserve) => {
    const _market = market?.find((m) => m.address.toLowerCase() === _reserve.reserve.underlyingAsset.toLowerCase());
    let sumOfIncentiveAPY = 0;
    if (_market) {
      if (_market.borrowIncentive && _market.borrowIncentive.length > 0) {
        sumOfIncentiveAPY = _market.borrowIncentive.reduce(
          (accumulator, currentIncentive) =>
            // accumulator + +currentIncentive.incentiveAPR === Infinity ? 0 : +currentIncentive.incentiveAPR,
            accumulator + 0,
          0,
        );
      }

      sumOfWeightedBorrowApy += +_reserve.totalBorrowsUSD * (-+_market.borrowApy + sumOfIncentiveAPY);
    }
  });

  return (sumOfWeightedSupplyApy + sumOfWeightedBorrowApy) / +totalSuppliedInUSD;
};

export const convertAaveV3ReserveToHanaAsset = (reserves: AaveV3IUserReserve[], isSupplied: boolean = true): IAsset[] => {
  let output: IAsset[] = [];

  reserves.forEach((_reserve) => {
    const reserveAssetAddress = _reserve.underlyingAsset;

    const currentMarket = Object.values(markets).find(
      (_market) => _market.address?.toLowerCase() === reserveAssetAddress.toLowerCase(),
    );

    if (currentMarket) {
      output.push({
        name: currentMarket.name,
        symbol: currentMarket.symbol,
        address: currentMarket.address,
        aTokenAddress: currentMarket.aTokenAddress,
        vTokenAddress: currentMarket.vTokenAddress,
        decimals: currentMarket.decimals,
        image: currentMarket.image,
        enabled: currentMarket.enabled,
        amount: isSupplied ? _reserve.underlyingBalance : _reserve.variableBorrows,
        amountInUSD: isSupplied ? _reserve.underlyingBalanceUSD : _reserve.variableBorrowsUSD,
        isCollateral: _reserve.usageAsCollateralEnabledOnUser,
        liquidationThreshold: _reserve.reserve ? _reserve.reserve.formattedReserveLiquidationThreshold:'',
        displayDecimal: currentMarket.displayDecimal,
      });
    }
  });

  return output;
};

export const convertAaveV3HealthReserveToHanaAsset = (data: HealthData): HanaHealthData => {
  let output: HanaUserReserves[] = [];
  let user: string = ''
  let totalBorrowsUSD: number = 0
  let totalCollateralUSD: number = 0

  data.userReservesData.forEach((reserve) => {
    const reserveAssetAddress = reserve.underlyingAsset;

    const currentMarket = Object.values(markets).find(
      (_market) => _market.address?.toLowerCase() === reserveAssetAddress.toLowerCase(),
    );
    let regex = /0x[a-fA-F0-9]+/g;
    let matches = reserve.id.match(regex);
    user = matches?matches[0] : ''
    if (currentMarket) {
      totalBorrowsUSD += +reserve.variableBorrowsUSD
      totalCollateralUSD += +reserve.underlyingBalanceUSD
      output.push({
        ...reserve,
        name: currentMarket.name,
        symbol: currentMarket.symbol,
        decimals: currentMarket.decimals+"",
        image: currentMarket.image,
      });
    }
  });

  return { userReservesData: output, address: user, totalBorrowsUSD: totalBorrowsUSD+'', totalCollateralUSD: totalCollateralUSD+'', healthFactor: data.healthFactor};
};

const convertAaveV3RewardsToHanaRewards = (incentives: { [key: string]: AaveV3Incentive }): IRewards[] => {
  let output: IRewards[] = [];

  Object.entries(incentives).forEach(([rewardTokenAddress, _incentive]) => {
    const currentMarket = Object.values(markets).find(
      (_market) => _market.address?.toLowerCase() === rewardTokenAddress.toLowerCase(),
    );

    // TODO: may want to use BigInt calculation
    const _amount = +_incentive.claimableRewards / 10 ** _incentive.rewardTokenDecimals;

    if (currentMarket) {
      output.push({
        ...currentMarket,
        amount: String(_amount),
        amountInUSD: String(_amount * +_incentive.rewardPriceFeed),
      });
    }
  });

  return output;
};
